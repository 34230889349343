import React, { ReactNode, createRef } from 'react';
import { InvisibleButton } from '@primitives';
import styled from 'styled-components';


interface IAccordionHeader {
  onClick: Function;
  title: string | ReactNode;
  renderHeading: Function;
  isOpen: boolean;
  id: string;
}

const Header = styled(InvisibleButton)`
  width: 100%;
  text-align: left;
  outline: none;
`;

const AccordionHeader: React.FC<IAccordionHeader> = ({
  onClick, title, renderHeading, isOpen, id,
}) => {
  const header = createRef<HTMLDivElement>();

  const delay = async (duration: number) => new Promise((resolve) => setTimeout(resolve, duration));

  const onHeaderClick = async () => {
    onClick(id);

    if (header.current) {
      // Ref disappears when executing delay, so..
      const immutable = Object.freeze({ ref: header.current });
      await delay(250);
      immutable.ref.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  };

  return (
    <div ref={header}>
      <Header id={id} onClick={onHeaderClick}>
        {renderHeading ? renderHeading(title, isOpen)
          : <h3>renderHeading missing</h3>}
      </Header>
    </div>
  );
};

export { AccordionHeader };
