import React, { useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage as M } from "gatsby-plugin-intl";;
import { AgreementDepositTable } from "@components/Table";
import { AgreementGeneralForm, AgreementPartiesForm } from "@components/Form";
import { TextToggleAccordion } from "@components/Accordion";
import { Box, Heading, Hr } from "@primitives";
import snakeCase from "lodash.snakecase";
import { useStores } from "@stores";
import { observer } from "mobx-react";
import { LinkButton } from "@components/Primitives";
import { until } from "../../styles/theme-utils";
import { AgreementStatusIndicator } from "./AgreementStatusIndicator";
import { getAgreementStatus } from "../../utils/myInformationUtils";
import { AgreementDocuments } from "./AgreementDocuments";

interface Agreement {
  agreement: IAgreement;
  fields: any;
}

const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${until("md", `{ flex-direction: column; align-items: flex-start }`)}
`;

const TitleContainer = styled(Box)`
  display: flex;
  ${until("md", `{ flex-direction: column; }`)}
`;

const Agreement: React.FC<Agreement> = observer(({ agreement, fields }) => {
  const {
    agreementStore: {
      getFullAgreement,
      fullAgreements,
      agreementParties: allAgreementParties,
      getAgreementParties,
      getDocuments,
      documents,
      documentState,
      selectedAgreementId
    },
    paymentStore: { deposits: allDeposits, getDeposits },
  }: {
    agreementStore: IAgreementStore;
    paymentStore: IPaymentStore;
  } = useStores();

  const fullAgreement = fullAgreements.find(
    (fullAgreement) => fullAgreement.agreement_id === agreement.agreement_id
  );
  const agreementParties = allAgreementParties.find(
    (ap) => ap.agreement_id === agreement.agreement_id
  )?.agreement_parties;
  const deposits = allDeposits.find(
    (deposit) => deposit.agreement_id === agreement.agreement_id
  )?.deposits;
  const tenantsDocuments = documents.find(
    (document) => document.agreement_id === agreement.agreement_id
  );

  useEffect(() => {
    getDocuments(agreement.agreement_id);
    // console.log(documents.length)
    if (!fullAgreement) getFullAgreement(agreement.agreement_id);
    if (
      !allAgreementParties.find(
        (p) => p.agreement_id === agreement.agreement_id
      )
    )
      getAgreementParties(agreement.agreement_id);
    if (!deposits) getDeposits(agreement.agreement_id);
    if (
      documentState !== "Loading" &&
      documentState !== "Error" &&
      selectedAgreementId &&
      !documents.find(
        (tenantDocObj) => tenantDocObj.agreement_id === selectedAgreementId
      )
    ) {
      (selectedAgreementId);
    }
  }, [agreement]);

  if (!fullAgreement || !agreementParties) return null;

  const sections = [
    {
      children: (
        <AgreementPartiesForm
          id="parties-information"
          data={agreementParties || []}
          fields={fields.parties}
        />
      ),
      title: <M id="pages.myInformation.agreementsPage.partiesInformation" />,
      id: "parties",
    },
    {
      children: (
        <AgreementGeneralForm
          id="general-information"
          data={fullAgreement}
          fields={fields.general}
        />
      ),
      title: <M id="pages.myInformation.agreementsPage.generalInformation" />,
      id: "general",
    },
    {
      children: (
        <AgreementDepositTable id="deposit-information" data={deposits} />
      ),
      title: <M id="pages.myInformation.agreementsPage.depositInformation" />,
      id: "deposit",
    },
    {
      children: (
        <AgreementDocuments
          id="document-information"
          items={tenantsDocuments?.documents}
          tenantId={tenantsDocuments?.agreement_id}
        />
      ),
      title: <M id="pages.myInformation.agreementsPage.documentInformation" />,
      id: "documents",
    },
  ];

  const status = getAgreementStatus(
    fullAgreement.start_date,
    fullAgreement.end_date,
    fullAgreement.termination_date
  );

  return (
    <Box>
      <Header pt="1" pb="2">
        <TitleContainer>
          <Heading color="textBlue" level="2">
            {fullAgreement.dwelling.address}
          </Heading>
          <AgreementStatusIndicator
            startDate={fullAgreement.start_date}
            endDate={fullAgreement.end_date}
            terminated={fullAgreement.termination_date}
          />
        </TitleContainer>
        {status === "beginning" ||
          (status === "active" && (
            <LinkButton
              href="https://portaali.tampuuri.fi/yhkodit/vuokrasopimuksenirtisanominen"
              target="_blank"
              id={`terminate-${agreement.agreement_id}`}
            >
              <M id="pages.myInformation.agreementsPage.terminateAgreement" />
            </LinkButton>
          ))}
      </Header>
      <TextToggleAccordion
        id={snakeCase(fullAgreement.dwelling.address)}
        items={sections}
      />
      <Hr />
    </Box>
  );
});

export { Agreement };
