import React from 'react';
import {
  Text, Link,
} from '@primitives';
import styled from 'styled-components';
import { AccordionItem } from './Accordion';

interface IAccordionLinkList {
  items: AccordionItem[];
}


const LinkList = styled.ul`
  padding: 1rem 0;
  li {
    display: block;
    padding: 0.5rem 0;
  }
`;

const AccordionLinkList: React.FC<IAccordionLinkList> = ({ items }) => {
  return (
    <LinkList>
      {items.map((item) => (
        <li key={item.href}>
          <Text underline>
            <Link to={item.href}>{item.title}</Link>
          </Text>
        </li>
      ))}
    </LinkList>
  );
};

export { AccordionLinkList };
