import React, { ReactNode } from 'react';
import {
  Box, Heading, Text,
} from '@primitives';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { rem } from 'polished';
import { Accordion } from '.';

interface ITextToggleAccordion {
  items: Item[];
  id: string;
  renderContent?: (item: any) => ReactNode;
}

type Item = {
  title: string | ReactNode;
  children: string | ReactNode;
  id: string;
};

const ItemHeader = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Title = styled(Heading)`
  padding: 1rem 0;
  &:hover {
    color:  ${(props) => props.theme.colors.mainBlue};
  }
`;

const ToggleContainer = styled(Box)`
  width: ${rem('60px')};
  display: flex;
  text-transform: uppercase;
  &:focus {
    outline: none;
  }

`;


const TextToggleAccordion: React.FC<ITextToggleAccordion> = ({ items, id, renderContent }) => {
  const renderHeading = (title: Item['title'], isOpen: boolean) => {
    const toggleText = isOpen ? 'close' : 'open';
    return (
      <ItemHeader>
        <ToggleContainer>
          <Text color="mainBlue" fontWeight="500">
            <M id={`common.content.accordion.${toggleText}`} />
          </Text>
        </ToggleContainer>
        <Title level="4">{title}</Title>
      </ItemHeader>
    );
  };

  const getContent = (children: Item['children']) => (
    <Box mt="2" mb="2">{children}</Box>
  );

  return (
    <Accordion
      id={id}
      items={items}
      renderHeading={renderHeading}
      renderContent={renderContent || getContent}
    />
  );
};

export { TextToggleAccordion };
