import { BlueBox } from "@components/BlueBox";
import { Box, Link } from "@components/Primitives";
import { FormattedMessage as M } from "gatsby-plugin-intl";
import React, {useState} from "react";
import styled from "styled-components";
import FileSaver from "file-saver";
import { getAgreementDocument } from  "../../services/api";;

interface AgreementDocuments {
  id: string;
  items: IDocument[] | undefined;
  tenantId: number
}

const LinkContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
`;

const FileList = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
`;

const AgreementDocuments: React.FC<AgreementDocuments> = ({ items, tenantId, id }) => {
  const [loading, setLoading] = useState(false);

  const onDownloadClick = async (file: IDocumentFile) => {
    setLoading(true);
    try {
    const response = await getAgreementDocument(tenantId, file.download_id);
    const blob = new Blob([response.data]);
    FileSaver.saveAs(blob, file.name);
    } catch (error) {
    console.error(error);
    } finally {
    setLoading(false);
    }
  };

  const renderDocumentFiles = () =>
    items?.map((item: IDocument) =>
      item.files.map((file: IDocumentFile) => (
        <Link
          key={file.name}
          href={'#'}
          color="mainBlue"
          onClick={() => onDownloadClick(file)}
        >
          {file.name}
        </Link>
      ))
    );

  return (
    <>
      <BlueBox alignLeft={true}>
        <M id="pages.myInformation.agreementsPage.documentNotice" />
        <LinkContainer mt="2">
          <Link to="/living" underline>
            <M id="pages.living.living" />
          </Link>
        </LinkContainer>
      </BlueBox>
      <FileList>
        {items && items.length > 0 ? (
          renderDocumentFiles()
        ) : (
          <M id="pages.myInformation.agreementsPage.noDocuments" />
        )}
      </FileList>
    </>
  );
};

export { AgreementDocuments };
