import React from 'react';
import {
  Box, Heading,
} from '@primitives';
import styled from 'styled-components';
import { Accordion } from '.';

interface IFaqAccordion {
  items: Item[];
  id: string;
}

type Item = {
  title: string;
  children?: string;
  id: string | number;
};

const ItemHeader = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-top: 1rem;
`;

const Title = styled(Heading)`
  padding: 1rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.cyan};
  max-width: 815px;
  &:hover {
    color:  ${(props) => props.theme.colors.mainBlue};
  }
`;

const Container = styled(Box)`
  .accordion-item-content.open {
    background: ${(props) => props.theme.colors.contentGrey};
    padding: 1rem 2rem;
    margin-top: 1.5rem;
    max-width: 815px;
  }
`;

const FaqAccordion: React.FC<IFaqAccordion> = ({ items, id }) => {
  const renderHeading = (title: Item['title']) => (
    <ItemHeader>
      <Title level="3">{title}</Title>
    </ItemHeader>
  );

  return (
    <Container className="faq-accordion">
      <Accordion contentKey="content" id={id} items={items} renderHeading={renderHeading} />
    </Container>
  );
};

export { FaqAccordion };
