import React, { ReactNode } from 'react';
import { Box, Heading } from '@primitives';
import { transitions } from 'polished';
import styled from 'styled-components';
// @ts-ignore
import Arrow from '@icons/faq_arrow.svg';
import { Accordion } from '.';
import { AccordionLinkList } from './AccordionLinkList';
import { AccordionItem } from './Accordion';

interface IArrowToggleAccordion {
  items: Item[];
  id: string;
  renderContent?: (item: any) => ReactNode;
  titleAccessor?: string;
  childAccessor?: string;
}

export type Item = {
  title: string;
  id: string;
  children: AccordionItem[];
};

const ItemHeader = styled(Box)`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.cyan};
`;
interface IIconContainer {
  isOpen: boolean;
}
const IconContainer = styled(Box)`
  padding: 0 1rem;
  ${transitions(['transform'], '0.25s ease-in 0.01s')};
  transform: ${(props: IIconContainer) => (props.isOpen ? 'rotate(0deg) scaleX(-1)' : 'rotate(180deg) scaleX(-1)')};
`;

const Title = styled(Heading)`
  padding: 1rem 0;
  width: 100%;
  &:hover {
    color:  ${(props) => props.theme.colors.mainBlue};
  }
`;

const ArrowToggleAccordion: React.FC<IArrowToggleAccordion> = ({ items, id, childAccessor, renderContent, titleAccessor }) => {
  const renderHeading = (title: Item['title'], isOpen: boolean) => (
    <ItemHeader>
      <Title level="3">{title}</Title>
      <IconContainer isOpen={isOpen}><Arrow /></IconContainer>
    </ItemHeader>
  );

  const getContent = (childItems: Item['children']) => (
    <Box>
      <AccordionLinkList items={childItems} />
    </Box>
  );

  return (
    <Accordion
      id={id}
      items={items}
      renderHeading={renderHeading}
      renderContent={renderContent || getContent}
      titleAccessor={titleAccessor}
      childAccessor={childAccessor}
    />
  );
};

export { ArrowToggleAccordion };
