/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { graphql } from 'gatsby';
import { ContentPageLayout } from '@components/Layout';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { Agreement } from '@components/Agreement';
import { NaviButtons } from '@components//NaviButtons';
import { TextSection } from '@components/TextSection';
import { PAGE_NAV_ITEMS, AGREEMENT, PARKING_LOT } from '@constants';
import { observer } from 'mobx-react';
import { useStores } from '@stores';
import { Box, Spinner, LinkButton } from '@components/Primitives';
import { TextToggleAccordion } from '@components/Accordion';
import { Heading } from '@primitives';
import { ReadOnlyForm } from '../../components/Form/Form';
import { getFormattedDate } from '@utils';
import { ReadonlyFormRow } from '@components/FormRow';
import { AgreementStatusIndicator } from '@components/Agreement/AgreementStatusIndicator';
import styled from 'styled-components';
import { getAgreementStatus } from '../../utils/myInformationUtils';

interface MyInformationAgreementPageProps {
  data: IMarkdownData;
}

const AgreementHeader = styled.div`
  display: flex;
`;

const AgreementHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const MyInformationAgreementPage: FC<MyInformationAgreementPageProps> = observer(({
  data: {
    markdownRemark: { frontmatter: { blocks: texts } },
  },
}) => {
  const {
    agreementStore: { agreements, state, getCurrentUserParkingLots, currentUserParkingLots },
  }: {
    agreementStore: IAgreementStore,
  } = useStores();

  useEffect(() => {
    getCurrentUserParkingLots();
  }, [getCurrentUserParkingLots]);

  const customRenderer = (field: any, i: number, values: any) => {
    if (field.id === 'parkingLotTimespan') {
      return <ReadonlyFormRow
        id={`${values.number}-timespan`}
        label="parkingLotTimespan"
        value={`${getFormattedDate(values?.start_date)} – ${getFormattedDate(values?.end_date)}`}
      />
    }
    return null;
  };

  return (
    <ContentPageLayout wideContent>
      <TextSection
        title={<M id='fields.label.agreement.title'/>}
        text={<M id='fields.label.agreement.text'/>}
        afterTitle={<NaviButtons items={PAGE_NAV_ITEMS.agreement} />}
      />
      {state === 'Loading' && <Spinner color="mainBlue" />}
      <div className="agreements">
        {agreements?.map((agreement) => (
          <Agreement key={agreement.agreement_id} agreement={agreement} fields={AGREEMENT} />
        ))}
        {currentUserParkingLots?.map((parkingLot) => {
          const status = getAgreementStatus(parkingLot.start_date, parkingLot.end_date)

          return (
            <Box id={parkingLot.number}>
              <AgreementHeaderWrapper>
                <AgreementHeader>
                  <Heading color="textBlue" level="2">
                    <M id="pages.myInformation.agreementsPage.parkingLot.title" values={{ number: parkingLot.number }} />
                  </Heading>
                  <AgreementStatusIndicator startDate={parkingLot.start_date} endDate={parkingLot.end_date} />

                </AgreementHeader>
                {status === 'beginning' || status === 'active' && (
                  <LinkButton
                    href="https://portaali.tampuuri.fi/yhkodit/vuokrasopimuksenirtisanominen"
                    target="_blank"
                    id={`terminate-${parkingLot.number}`}
                  >
                    <M id="pages.myInformation.agreementsPage.terminateAgreement" />
                  </LinkButton>
                )}
              </AgreementHeaderWrapper>
              <TextToggleAccordion
                id={parkingLot.number}
                items={[{
                  children: <ReadOnlyForm styles={['singleColumn']} values={parkingLot} fields={PARKING_LOT} id={`parking-lot-${parkingLot.number}`} customRenderer={customRenderer} />,
                  title: <M id="pages.myInformation.agreementsPage.generalInformation" />,
                  id: 'general',
                }]}
              />
            </Box>
          );
        })}
      </div>
    </ContentPageLayout>
  );
});

export const pageQuery = graphql`
 query MyInformationAgreementQuery {
    markdownRemark(frontmatter: {path: {eq: "/me/agreement/"}}) {
    frontmatter {
    title
      path
        blocks {
          text
        title
      }
    }
  }
}`;

export default MyInformationAgreementPage;
