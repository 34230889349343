/* eslint-disable react/no-danger */
import React, { ReactNode, useState, memo, useEffect } from 'react';
import { Box } from '@primitives';
import styled from 'styled-components';
import { AccordionHeader } from '.';

export type AccordionItem = {
  title: string | ReactNode;
  children: any;
  id: string;
  href?: string;
  [key: string]: any;
}

interface IAccordion {
  id: string;
  items: AccordionItem[];
  renderContent?: (item: any) => ReactNode;
  renderHeading: (title: string, isOpen: boolean) => ReactNode;
  renderToggle?: (item: any) => ReactNode;
  contentKey?: string;
  titleAccessor?: string;
  childAccessor?: string;
}

interface IContent {
  isOpen: boolean;
}
const Content = styled(Box) <IContent>`
  height: ${(props) => (props.isOpen ? 'auto' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  overflow: hidden;
  line-height: 145%;
  margin: auto;
`;

const AccordionList = styled.ul`
  list-style-type:none;
  margin:0;
  padding: 0;
`;

const Accordion: React.FC<IAccordion> = ({
  items, renderContent, renderHeading, id: accordionId, contentKey,
  titleAccessor, childAccessor, ...rest
}) => {
  const [openItem, setOpenItem] = useState('');
  const isOpen = (id: string) => openItem === id;

  const onHeaderClick = (id: string) => {
    if (id === openItem) {
      setOpenItem('');
      return;
    }
    setOpenItem(id);
  };

  useEffect(() => {
    const url = window.location.href;
    const currentItem = items?.find(item => url.endsWith(`#${item.id}`));
    if (currentItem && !isOpen(currentItem.id)) {
      setOpenItem(currentItem.id);
    }
  }, []);

  if (!items || !items.map) return null;

  return (
    <Box {...rest}>
      <AccordionList>
        {items?.map((item: AccordionItem) => {
          const title = titleAccessor ? item[titleAccessor] : item.title;
          const children = childAccessor ? item[childAccessor] : item.children;
          return (
            <li key={item.id}>
              <Box id={`${accordionId}_${item.id}`}>
                <a href={`#${item.id}`}>
                <AccordionHeader
                  onClick={() => onHeaderClick(item.id)}
                  isOpen={isOpen(item.id)}
                  title={title}
                  renderHeading={renderHeading}
                  id={item.id}
                />
                </a>
                {isOpen && (
                  <Content className={`accordion-item-content ${isOpen(item.id) && 'open'}`} isOpen={isOpen(item.id)}>
                    {(!contentKey && renderContent) && renderContent(children)}
                    {contentKey && <div dangerouslySetInnerHTML={{ __html: item[contentKey] }} />}
                  </Content>
                )}
              </Box>
            </li>
          );
        })}
      
      </AccordionList>
    </Box>
  );
};

const MemoizedAccordion = memo(Accordion);
export { MemoizedAccordion as Accordion };
