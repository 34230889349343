import React from 'react';
import styled from 'styled-components';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { rem } from 'polished';
import { until } from '../../styles/theme-utils';
import { getAgreementStatus } from '../../utils/myInformationUtils';

interface AgreementStatusIndicator {
  startDate?: string;
  endDate?: string | null;
  terminated?: string | null;
}

const getStatusIndicatorColor = (status: AgreementStatus, theme: ITheme) => {
  switch (status) {
    case 'beginning':
      return theme.colors.statusPurple;
    case 'terminated':
      return theme.colors.statusOrange;
    case 'active':
      return theme.colors.statusGreen;
    default:
    case 'ended':
      return theme.colors.statusGrey;
  }
};

const StatusIndicator = styled.span`
  background: ${(props: { status: AgreementStatus; theme: ITheme }) => getStatusIndicatorColor(props.status, props.theme)};
  height: ${rem('10px')};
  width: ${rem('10px')};
  border-radius: ${rem('10px')};
  margin-left: 2rem;
  margin-right: 0.5rem;
  display: block;
  ${until('md', `{ margin: 1rem 0; margin-right: 0.5rem }`)}
`;

const Status = styled.span`
  display: flex;
  align-items: center;
`;

const AgreementStatusIndicator: React.FC<AgreementStatusIndicator> = ({ startDate, endDate, terminated }) => {
  return (
    <Status>
      <StatusIndicator status={getAgreementStatus(startDate, endDate, terminated)} />
      <M id={`pages.myInformation.agreementsPage.agreementStatus.${getAgreementStatus(startDate, endDate, terminated)}`} />
    </Status>
  );
};

export { AgreementStatusIndicator };
